import React, { Suspense, lazy } from 'react';
import AuthGuard from './components/AuthGuard';
import DashboardLayout from '../shared/layout/DashboardLayout';
import GuestGuard from './components/GuestGuard';
import LoadingScreen from '../shared/components/LoadingScreen';

const Loadable = (Component) => (props) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboard pages
const PosterCreate = Loadable(lazy(() => import('../pages/PosterCreate')));
const PosterEdit = Loadable(lazy(() => import('../pages/PosterEdit')));
const Dashboard = Loadable(lazy(() => import('../pages/Dashboard')));
const Tabloides = Loadable(lazy(() => import('../tabloide/index')));
const Layouts = Loadable(lazy(() => import('../tabloide/layouts')));
const NovoLayoutTabloide = Loadable(
  lazy(() => import('../tabloide/novo-layout-tabloide/NovoLayoutTabloide'))
);
const NovoTabloide = Loadable(lazy(() => import('../tabloide/novo-tabloide/NovoTabloide')));
const PosterList = Loadable(lazy(() => import('../pages/PosterList')));
const ProductList = Loadable(lazy(() => import('../pages/ProductList')));
const Customize = Loadable(lazy(() => import('../pages/Customize')));
const Settings = Loadable(lazy(() => import('../pages/Settings')));
const ImagensDataBase = Loadable(lazy(() => import('../modules/PortifolioImage/page/ImagePlofile')));

// Authentication pages
const Authentication = Loadable(lazy(() => import('../pages/Authentication')));

// Error page
const NotFound = Loadable(lazy(() => import('../pages/NotFound')));

const routes = [
  {
    path: 'login',
    element: (
      <GuestGuard>
        <Authentication />
      </GuestGuard>
    )
  },
  {
    path: '/',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    children: [
      { path: '/', element: <Dashboard />, name: '' },
      { path: '/configuracoes', element: <Settings />, name: 'Configurações' },
      { path: '/tabloides', element: <Tabloides />, name: 'Tabloides' },
      { path: '/layouts', element: <Layouts />, name: 'Layouts' },
      {
        path: '/novo-layout-tabloide',
        element: <NovoLayoutTabloide />,
        name: 'Novo Layout Tabloide'
      },
      {
        path: '/layout/:layoutId',
        element: <NovoLayoutTabloide />,
        name: 'Editar Layout Tabloide'
      },
      { path: '/novo-tabloide', element: <NovoTabloide />, name: 'Novo Tabloide' },
      { path: '/tabloide/:tabloideId', element: <NovoTabloide />, name: 'Editar Tabloide' },
      { path: '/campanhas', element: <Customize />, name: 'Campanhas' },
      { path: '/meus-cartazes', element: <PosterList />, name: 'Todos os cartazes' },
      { path: '/novo-cartaz', element: <PosterCreate />, name: 'Criar cartaz' },
      { path: '/editar-cartaz/:posterId', element: <PosterEdit />, name: 'Editar Cartaz' },
      { path: '/produtos', element: <ProductList />, name: 'Todos os produtos' },
      { path: '/banco-imagens', element: <ImagensDataBase />, name: 'Banco de Imagens' }
    ]
  },
  {
    path: '401',
    element: <NotFound />
  },
  {
    path: '404',
    element: <NotFound />
  },
  {
    path: '500',
    element: <NotFound />
  },
  {
    path: '*',
    element: <NotFound />
  }
];

export default routes;
