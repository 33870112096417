import React, { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Form, Title, LoginButton, InputLogin } from './styles';
import Input from '../../../../shared/components/Input';
import PasswordInput from '../../../../shared/components/PasswordInput';
import useAuth from '../../../../shared/hooks/useAuth';
import { Box, FormHelperText,CircularProgress } from '@material-ui/core';
import api from '../../../../lib/api';
import { BASE_API_NODE } from 'services/config/baseUrl';
const Login = () => {
  const [loading, setLoading] = useState(false)
  const { login, resetPasswordd } = useAuth();


  const setSession = (accessToken) => {

    if (accessToken) {
      localStorage.setItem('@matrixPrint:accessNewToken', accessToken);
      // axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    }
  };

  

  const LoginNew = async (username, password) => {
    try {
      const response = await fetch(`${BASE_API_NODE}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          username: username,
          password: password
        })
      });

      console.log(response,'...data')

      const data = await response.json();

      console.log(data,'...data')
      setSession(data.token);

      return data;
    } catch (error) {
      console.log(error,'...error')

      throw new Error('Login failed');
    }
    // try {
    //   await api
    //     .post('/auth/login', {
    //       username,
    //       password
    //     })
    //     .then((loginResponse) => {
    //       const { jwtToken } = loginResponse.data;

    //       console.log(jwtToken,'...jwtToken')
    //       setSession(jwtToken);

    //     })
       
    // } catch (err) {
    //   if (err.status === 401) {
    //     const notify = () => toast.error('E-mail ou senha incorreta');
    //     notify();
    //   } else if (err.status === 404) {
    //     const notify = () => toast.error('Usuário não encontrado');
    //     notify();
    //   } else {
    //     const notify = () =>
    //       toast.error(
    //         'Ocorreu um erro por favor tente novamente ou entre em contato com nosso suporte'
    //       );
    //     notify();
    //   }
    // }
  };
  return (
    <Formik
      initialValues={{
        username: '',
        password: '',
        submit: null
      }}
      validationSchema={Yup.object().shape({
        username: Yup.string()
          .email('O e-mail precisa ser válido')
          .max(255)
          .required('Por favor insira um e-mail'),
        password: Yup.string().max(255).required('Por favor insira uma senha')
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        setSubmitting(true);
        setLoading(true)
        try {
          await LoginNew(values.username, values.password);
          await login(values.username, values.password);
          setStatus({ success: true });
          setLoading(false)

        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err.message });
          setLoading(false)

        }
        setSubmitting(false);
        setLoading(false)

      }}
    >
      {({ errors, handleChange, handleSubmit, isSubmitting, touched, values }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <Title>Entrar.</Title>
          <InputLogin
            placeholder='E-mail'
            type="email"
            name="username"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.username}
          /> 
          <p style={{ color: 'red' }}> {touched.username && errors.username}</p>
          <InputLogin
            name="password"
            placeholder='Senha'
            type="password"
            onChange={handleChange}
            // onBlur={handleBlur}
            value={values.password}
          />
          <p style={{ color: 'red' }}>{touched.password && errors.password}</p>

          {errors.submit && (
            <Box sx={{ mt: 3 }}>
              <FormHelperText error>
                <div>{errors.submit}</div>
              </FormHelperText>
            </Box>
          )}


          <LoginButton type="submit" disabled={isSubmitting}>
      
            {loading ?   
              <CircularProgress  size={25}/>:
              'Entrar'
            }
          </LoginButton>
        </Form>
      )}
    </Formik>
  );
};

export default Login;