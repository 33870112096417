import axios from 'axios';
import { BASE_API_NODE } from 'services/config/baseUrl';

const api = axios.create({
  baseURL: BASE_API_NODE
  // baseURL: 'https://backenddev.matrixprint.com.br/'
  // baseURL: 'https://backend.matrixprint.com.br/',
  // baseURL: 'http://cartaz-impresso-portifolio-backend.us-west-2.elasticbeanstalk.com/'
  // baseURL: 'http://localhost:8080/'
});


api.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo de errado aconteceu')
);

export default api;




