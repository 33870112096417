import axios from 'axios';
import { BASE_API } from 'services/config/baseUrl';

const axiosInstance = axios.create({
  baseURL: BASE_API,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS'
  }
});

axiosInstance.interceptors.request.use(async (config) => {
  const token = localStorage.getItem('@matrixPrint:accessToken');

  if (token) {
    config.headers = config.headers ? config.headers : {};
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Algo de errado aconteceu')
);

export default axiosInstance;




